import {
	DrivenBox,
	DrivenButton,
	DrivenContainer,
	DrivenLink,
	DrivenTypography,
} from '@drivenbrands/driven-components';
import { useMediaQuery, useTheme } from '@mui/material';
import { graphql, PageProps } from 'gatsby';

import CTABanner from '../components/cta-banner';
import { GatsbyLinkBehavior } from '../components/gatsby-link-behavior';
import MarkdownContent from '../components/mdx/markdown-content';
import { SectionType } from '../components/section';
import { CustomerTestimonials } from '../components/customer-testimonials';
import { SEO } from '../components/seo';
import ChipList from '../components/chip-list';
import { formatPhoneNumber , stateNameToAbbreviation, generateHeadData, getStoreStatus } from '../utils';
import { ComponentMatch } from '../hooks';
import { consolidateStoreHours, formatStoreHours } from '../utils/consolidateStoreHours';
import { LocationDetailBreadcrumbs } from '../components/breadcrumbs/breadcrumbs';
import { captureCustomDataLayerEvent } from '../services';

const MobileLocationDetailsPage = (
	{
		data: { ctaBanner, contentfulLocationContent, storeLocation, detailPage }
	}: PageProps<Queries.DetailsPageQueryQuery>) => {

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));
	const isTablet = useMediaQuery(theme.breakpoints.up('md'));

	const store = storeLocation;

	if (!store) return null;

	const { storeHours, isOpen } = getStoreStatus(store.hours);
	const storeStateAbbr = stateNameToAbbreviation(store.store_state)?.toLowerCase() ?? '';
	const locationDetailBlocks = detailPage?.nodes[0]?.blocks;
	const locationContentData = contentfulLocationContent;

	const locationContent = (fallbackContent: string) => {
		{/* use as a short-term default content until we have location-specific content from our vendor.*/}
		// if (locationContentData && locationContentData.content?.childMarkdownRemark?.rawMarkdownBody) {
		// 	return locationContentData.content.childMarkdownRemark.rawMarkdownBody;
		// }

		return fallbackContent.replace(
			/\[CITY_NAME_DO_NOT_EDIT\]/g,
			store.store_city || store.store_state || ''
		);
	};

	return (
		<DrivenBox>
			<DrivenContainer>
				<LocationDetailBreadcrumbs
					store_city={store.store_city}
					store_state={store?.store_state}
					storeStateAbbr={storeStateAbbr}
					path={store.path}
				/>
				<DrivenBox
					sx={{
						my: { xs: 3, md: 6 },
						display: 'grid',
						gap: 3,
						alignItems: 'start',
						gridTemplateColumns: { xs: '1fr', md: '55% 42.8%' },
						gridTemplateAreas: {
							xs: `
        				'heading'
        				'mobileOnly'
        				'map'
        				'location'
        				'actions'
        				'hours'
        				'disclaimer'
      				`,
							md: `
        				'heading map'
        				'location map'
        				'actions map'
        				'hours map'
        				'disclaimer map'
      			`,
						},
					}}
				>
					<DrivenTypography
						component='h1'
						variant='h1'
						sx={{
							fontSize: { xs: 30, sm: 36, md: 40 },
							gridArea: 'heading',
						}}
					>
						{`Mobile Windshield Replacement & Repair in ${store.store_city}`}
					</DrivenTypography>
					<DrivenBox sx={{ gridArea: 'location' }}>
						<DrivenTypography component='p' variant='h3' sx={{
							fontSize: { xs: 18 },
							mb: '12px'
						}}>
							We Come to You in The Following Areas:
						</DrivenTypography>
						{locationContentData?.serviceArea
							?.childMarkdownRemark?.rawMarkdownBody && (
							<ChipList
								chips={locationContentData.serviceArea.childMarkdownRemark.rawMarkdownBody.split('|').filter((each, index) => index < 10 ? each : false)}
							/>
						)}
					</DrivenBox>
					<DrivenBox sx={{
						gridArea: 'actions',
						display: 'flex',
						flexDirection: 'column',
						gap: 3
					}}>
						<DrivenButton
							color='primary'
							href='/online-estimate/'
							LinkComponent={GatsbyLinkBehavior}
							variant='contained'
							sx={{
								borderRadius: 30,
								height: 48,
								textTransform: 'uppercase',
								width: 'fit-content',
							}}
							onClick={() =>
								captureCustomDataLayerEvent('location_page_get_quote', {
									store_id: store?.store_id
								})
							}
						>
							Get a quote
						</DrivenButton>
						<DrivenLink
							href={`tel:${formatPhoneNumber(store.organic_phone || store.store_phone)}`}
							sx={{
								display: 'flex',
								gap: 1,
								fontWeight: 600,
								color: '#0070B9'
							}}
						>
							<DrivenBox component='svg' sx={{
								width: 24,
								height: 24,
								fill: '#0070B9'
							}}>
								<use href='/assets/images/icons/phone-square.svg#phone-square'/>
							</DrivenBox>
							{formatPhoneNumber(store.organic_phone || store.store_phone)}
						</DrivenLink>
					</DrivenBox>
					<DrivenBox sx={{ gridArea: 'hours' }}>
						<DrivenTypography component='p' variant='h3' sx={{
							fontSize: { xs: 18 },
							mb: 1
						}}>Hours of Operation <DrivenTypography component='span' sx={{
							color: theme.palette.grey[500],
						}}>|</DrivenTypography>
							{isOpen ? (<>
								<DrivenTypography component='span' sx={{
									color: '#457A3B'
								}}> {storeHours}</DrivenTypography>
							</>) : <DrivenTypography component='span' sx={{
								color: theme.palette.error.main
							}}> {storeHours}</DrivenTypography>}
						</DrivenTypography>
						<DrivenTypography
							component='div'
							variant='body2'
							sx={{
								display:'flex',
								flexDirection: 'column',
								fontWeight: 400,
								color: theme.palette.grey[800],
							}}
						>
							{formatStoreHours(consolidateStoreHours(store.hours), theme)}
						</DrivenTypography>
					</DrivenBox>
					<DrivenBox sx={{ gridArea: 'disclaimer' }}>
						<DrivenTypography sx={{
							fontSize: { xs: 12 },
							fontStyle: 'italic'
						}}>
							All mobile work is weather permitting. Replacement cannot be completed in rainy/wet conditions or when such conditions seem imminent.
							All ice and snow must be removed from the hood, roof,  working area on the vehicle and around the vehicle, including the driveway.
							We reserve the right to reschedule the appointment if weather conditions are not favorable.
							If indoor space is provided, please ensure there is adequate space available to open doors on both sides of the vehicle and approximately three feet of clearance above the vehicle.
							Note that some conditions may apply should your vehicle require calibration services. Our Auto Glass Now representatives will confirm any and all requirements for this service at the time of the booking.
						</DrivenTypography>
					</DrivenBox>
					<DrivenBox sx={{
						gridArea: 'map',
					}}>
						<DrivenBox sx={{
							height: '40px',
							width: '249px',
							borderRadius: 1,
							border: '0.5px solid #004E82',
							boxSizing: 'border-box',
							display: 'flex',
							gap: 1,
							mb: { xs: 0, md: 3 },
							mt: { xs: -3, md: 0 },
						}}>
							<DrivenBox sx={{
								p: 1,
								backgroundColor: theme.palette.grey[200],
								borderBottomLeftRadius: 4,
								borderTopLeftRadius: 4,
							}}>
								<img src='/assets/images/icons/van-shuttle.svg' alt='Van shuttle icon' />
							</DrivenBox>
							<DrivenBox sx={{
								display: 'flex',
								alignItems: 'center',
							}}>
								<DrivenTypography component='span' variant='h6' sx={{
									fontSize: { xs: 16 },
									textTransform: 'uppercase',
									color: '#004E82',
									fontWeight: 700,
								}}>
									Mobile service only
								</DrivenTypography>
							</DrivenBox>
						</DrivenBox>
						{!isMobile && (
							<DrivenBox
								component='img'
								src='/assets/images/mobile-locations.png'
								alt='Blue AutoGlassNow van with branding and contact info driving on a road under a cloudy sky.'
								sx={{
									objectFit: 'cover',
									width: '100%'
								}}
							/>
						)}
					</DrivenBox>
				</DrivenBox>
			</DrivenContainer>
			{locationDetailBlocks &&
				locationDetailBlocks
					.map((block) => {
						const {
							id,
							contentSectionButton,
							body: fallbackContent,
							heading: fallbackHeading,
							bodyBlocks,
						} = block as SectionType;

						const { rawMarkdownBody } =
							fallbackContent?.childMarkdownRemark;

						return (
							<DrivenBox
								key={id}
								sx={{
									backgroundColor: 'grey.100',
								}}
							>
								<DrivenContainer>
									<DrivenBox
										sx={{
											display: 'flex',
											flexDirection: isTablet ? 'row' : 'column',
											gap: isTablet ? 10 : 0,
											py: isTablet ? 5 : 3,
										}}
									>
										<DrivenBox width={isTablet ? '50%' : '100%'}>
											{!locationContentData?.heading && (
												<DrivenBox my={3}>
													<DrivenTypography
														textAlign={isTablet ? 'initial' : 'center'}
														variant={fallbackHeading?.style}
													>
														{fallbackHeading?.text}
													</DrivenTypography>
												</DrivenBox>
											)}
											<DrivenBox className='locationContent'>
												<MarkdownContent>
													{locationContent('## Windshield Repair & Replacement in [CITY_NAME_DO_NOT_EDIT]\n' +
														'Are you searching for the best service center for auto glass repair in [CITY_NAME_DO_NOT_EDIT]? Look no further than Auto Glass Now!\n' +
														'\n' +
														'## Full Front, Rear, and Side Auto Glass and Windshield Services\n' +
														'When you need a car window replacement, you need Auto Glass Now. We are a leading windshield repair and replacement service center in [CITY_NAME_DO_NOT_EDIT], offering flexible scheduling, affordable rates, and quality services.')}
												</MarkdownContent>
											</DrivenBox>
											{/* Call now button */}
											{!isTablet && ComponentMatch(
												contentSectionButton,
												'ContentfulButton',
												{
													url: `tel:${formatPhoneNumber(
														store.organic_phone || store.store_phone
													)}`,
												}
											)}
										</DrivenBox>
										<DrivenBox width={isTablet ? '50%' : '100%'}>
											<DrivenBox my={3}>
												{/* This is only pulling in the check marks below location services...  */}
												{ComponentMatch([bodyBlocks[0]])}
											</DrivenBox>
										</DrivenBox>
									</DrivenBox>
								</DrivenContainer>
							</DrivenBox>
						);
					})}
			{store.testimonials && <CustomerTestimonials locationId={store.testimonials} />}
			{ctaBanner && <CTABanner {...ctaBanner} />}
		</DrivenBox>
	);
};

export const Head = (
	{ data: { storeLocation, contentfulLocationContent, detailPage }}: any) => {

	const {
		seoTitle,
		seoDescription,
		canonicalUrl,
		ogImageUrl,
		structuredData,
	} = generateHeadData(storeLocation, contentfulLocationContent, detailPage);

	return (
		<>
			<SEO
				path={`/${storeLocation.path}/`}
				title={seoTitle}
				description={seoDescription}
				canonical={canonicalUrl}
				ogImageUrl={ogImageUrl}
			/>
			<script type='application/ld+json'>
				{JSON.stringify(structuredData)}
			</script>
		</>
	);
};

export const data = graphql`
	query DetailsPageQuery($id: String, $node_locale: String, $store_id: Int) {
		ctaBanner: contentfulCtaBanner(
			contentful_id: { eq: "77ygezZ3jthDun8FZxcVJM" }
			node_locale: { eq: $node_locale }
		) {
			...CTABannerFields
		}

		contentfulLocationContent(
			storeId: { eq: $store_id }
			node_locale: { eq: $node_locale }
		) {
			id
			storeId
			heading
			metaDescription
			titleTag
			content {
				childMarkdownRemark {
					rawMarkdownBody
				}
			}
			serviceArea {
				childMarkdownRemark {
					rawMarkdownBody
				}
			}
			mobileRepair
		}

		storeLocation(id: { eq: $id }) {
			id
			store_postcode
			store_phone
			organic_phone
			is_active
			path
			store_address
			store_city
			store_id
			store_lat
			store_long
			store_name
			store_state
			sub_brand
			brand
			current_brand
			historic_brand
			geo_address
			testimonials

			hours {
				Monday {
					close
					isOpen
					open
				}
				Tuesday {
					close
					isOpen
					open
				}
				Wednesday {
					close
					isOpen
					open
				}
				Thursday {
					close
					isOpen
					open
				}
				Friday {
					close
					isOpen
					open
				}
				Saturday {
					close
					isOpen
					open
				}
				Sunday {
					close
					isOpen
					open
				}
			}
		}

		detailPage: allContentfulPage(
			filter: {
				node_locale: { eq: $node_locale }
				path: { eq: "/individual-mobile-location-detail-page" }
			}
		) {
			nodes {
				blocks {
					...HeroFields

					# ...SectionFields

					... on ContentfulSection {
						__typename
						id
						heading {
							text
							level
							style
						}
						contentSectionButton: actions {
							...ButtonFields
						}
						body {
							childMarkdownRemark {
								rawMarkdownBody
							}
						}
						bodyBlocks {
							...ListFields
						}
						backgroundColor
						inline
						blocks {
							...GridFields

							...TabsFields

							...ContentSectionFields

							...CardGroupFields

							...AccordionFields

							...ImageBannerFields
						}
						disclaimer {
							childMarkdownRemark {
								rawMarkdownBody
							}
						}
					}
				}
			}
		}
	}
`;

export default MobileLocationDetailsPage;
