import {
	DrivenBox, DrivenLink, DrivenTypography,
} from '@drivenbrands/driven-components';
import { CrossBrandImage } from './cross-brand-image';

const ExclusiveOffers = (props: any) => {
	const { images } = props;

	return (
		<>
			<DrivenTypography variant='h1' component='h1' sx={{
				fontSize: { xs: 30, sm: 40, md: 48 },
				textAlign: 'center',
				textTransform: { xs: 'capitalize' }
			}}>
				Exclusive offers from our family of brands!
			</DrivenTypography>
			<DrivenBox sx={{
				display: 'flex',
				gap: 2,
				flexDirection: { xs: 'column', sm: 'row' },
				width: { xs: '100%', md: '670px' },
				mx: 'auto',
				mt: 4,
			}}>
				<DrivenLink
					href='https://www.take5.com/locations/'
					target='_blank'
				>
					<CrossBrandImage image={images[0]} />
				</DrivenLink>
				<DrivenLink
					href='https://www.maaco.com/online-estimator/'
					target='_blank'
				>
					<CrossBrandImage image={images[1]} />
				</DrivenLink>
			</DrivenBox>
			<DrivenTypography sx={{
				fontSize: 14
			}}>
				<b>35% Off Overall Paint: </b>Discount applies to paint services only. Discount does not apply to the full estimate or additional services such as parts, bodywork, labor, surface repair, rust repair or preparation.
				Prices and participation may vary.  Valid for retail customers at the participating MAACO location only.
				Not valid for fleet or commercial services. Not valid with other promotions, discounts or insurance claims.
				Excludes specialty colors, materials and custom work. Some restrictions (or additional costs) apply to trucks, vans, SUVs and commercial vehicles.
				Offer not valid on work in progress. Additional exclusions may apply. Void where prohibited. See shop manager for complete details. Limited time offer.
			</DrivenTypography>
		</>
	);
}

export default ExclusiveOffers;
